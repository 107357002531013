import Home from "./index"
import React, { Component } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import { sectionIds } from "../constants/sectionIds"

export class Support extends Component {
  componentDidMount() {
    scrollTo(`#${sectionIds.waysYouCanGetInvolved}`)
  }

  render() {
    return <Home />
  }
}

export default Support
